import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@/styles/index.scss'
import 'amfe-flexible' //
import { initNativeBridge } from './utils/nativeBridge';
import { nativeBridgeConfig } from './utils/nativeBridge';
import { Locale } from 'vant';
import filters from './utils/filters'
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);
// import '@vant/touch-emulator';


import Vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/vant.css'
import touch from 'vue-directive-touch';
Vue.use(touch);

import VueTouch from "vue-touch";
Vue.use(VueTouch, { name: "v-touch" });
//

//
import { getRandomAlphaNum } from '@/utils/config.js'
Vue.prototype.getRandomAlphaNum = getRandomAlphaNum
import { preventReClick } from '@/utils/directive.js'
Vue.directive('preventReClick', preventReClick)
import { customerActionLog } from './api/utils.js';
Vue.prototype.$customerActionLog = customerActionLog;
initNativeBridge();
window.nativeBridgeConfig = nativeBridgeConfig;
//监听工具生产要打开
// import Vconsole from 'vconsole';
// let vconsole = new Vconsole;
// vconsole

// Vue.prototype.$api = api
import '@/styles/index.scss'
import '@/icons' // icon
Vue.use(Vant);
Vue.config.productionTip = false
//
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
//监听工具生产要打开
// import * as fundebug from "fundebug-javascript";
// import FundebugVue from "fundebug-vue";
// fundebug.apikey = "9e3696edf201948e4206c432f0d999a2a292a6151e027290485a81e05d1b10fd"
// new FundebugVue(fundebug).installVueErrorHandler(Vue); // Vue 2.x
// console.log(document.documentElement.style.fontSize, '$$$$$$$$$$$$$11111');
// window.addEventListener('resize', setRemUnit);
// window.addEventListener('orientationchange', setRemUnit);

function setRemUnit() {
  var remSize = document.documentElement.clientWidth / 10;
  document.documentElement.style.fontSize = remSize + 'px';
  // console.log(remSize,document.documentElement.style.fontSize,'$$$$$$$$$$$$$');
}

setRemUnit();
// let bsInfo;

// (async () => {
//   try {
//     const result = await nativeBridgeConfig.nativeCall("getBaseInfo");
//     console.log("app.vue:*********", result);
//     if (result) {
//       localStorage.setItem("BaseInfo", result);
//       localStorage.setItem("appCode", JSON.parse(result).appCode);

//       bsInfo = {
//         appCode: Number(JSON.parse(result).appCode),
//         version: JSON.parse(result).versionCode,
//         userCode: Number(JSON.parse(result).customerId),
//       };
//     }
//   } catch (error) {
//     console.log(`getBaseInfo-error: "${error}"`);

//     if (error.message === "SessionExpired") {
//       try {
//         await nativeBridgeConfig.nativeCall("loginOut");
//       } catch (logoutError) {
//         console.error(`loginOut-error: "${logoutError}"`);
//       }
//     } else {
//       console.error("An unexpected error occurred.");
//     }
//   }
// })();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
