import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('@/views/certificate/index'),

  },
  {
    path: '/extend',
    name: 'extend',
    component: () => import('@/views/extend/index'),

  },
  {
    path: '/repayment',
    name: 'repayment',
    component: () => import('@/views/repayment/index'),

  },
  {
    path: '/repaymentWay',
    name: 'repaymentWay',
    component: () => import('@/views/repaymentWay/index'),
  },
  {
    path: '/repaymentDetails',
    name: 'repaymentDetails',
    component: () => import('@/views/repaymentDetails/index'),
  },
  {
    path: '/yourself',
    name: 'yourself',
    component: () => import('@/views/yourself/index'),
  },
  {
    path: '/Receiving',
    name: 'Receiving',
    component: () => import('@/views/Receiving/index'),
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let appId = localStorage.getItem("appCode")
  document.title = `${appId}-${to.name}`
  // 获取页面名称
  const pageName = to.name;
  // 发送页面名称到 Google Analytics
  window.gtag("event", "page_view", {
    page_title: pageName,
  });
  // 记录页面进入时间
  const enterTime = Date.now();

  // 在路由离开守卫中计算页面停留时间
  to.meta.leaveGuard = router.afterEach((to, from) => {
    const stayTime = Date.now() - enterTime;

    // 发送页面停留时间到 Google Analytics
    window.gtag("event", "stay_time", {
      value: stayTime,
      event_category: "timing",
      event_label: pageName,
    });

    // 移除路由离开守卫
    to.meta.leaveGuard();
  });
  next()

})
export default router
