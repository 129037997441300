<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  async created() {
    // console.log("------app.vue");
    // console.log(document.documentElement.style.fontSize, "$$$$$$$$$$$22222");

    // let bsInfo;
    // try {
    //   const result = await nativeBridgeConfig.nativeCall("getBaseInfo");
    //   console.log("app.vue:*********", result);
    //   if (result) {
    //     localStorage.setItem("BaseInfo", result);
    //     localStorage.setItem("appCode", JSON.parse(result).appCode);

    //     bsInfo = {
    //       appCode: Number(JSON.parse(result).appCode),
    //       version: JSON.parse(result).versionCode,
    //       userCode: Number(JSON.parse(result).customerId),
    //     };
    //   }
    // } catch (error) {
    //   console.log(`getBaseInfo-error: "${error}"`);

    //   if (error.message === "SessionExpired") {
    //     try {
    //       await nativeBridgeConfig.nativeCall("loginOut");
    //     } catch (logoutError) {
    //       console.error(`loginOut-error: "${logoutError}"`);
    //     }
    //   } else {
    //     console.error("An unexpected error occurred.");
    //   }
    // }

    // if (bsInfo) {
    //   console.log(bsInfo, "&&&&&&&&&&&&&");
    //   this.$store.commit("getFromApp", bsInfo);
    // }
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
